import store from "@/store";
import Theme from "@/common/theme";

export default {
  methods: {
    isRole(role) {
      return store.getters["user/roles"].includes(role);
    },
    isRoleAdmin() {
      return store.getters["user/roles"].includes("super-admin");
    },
    isRoleParent() {
      return store.getters["user/roles"].includes("parent");
    },
    isRoleStudent() {
      return store.getters["user/roles"].includes("student");
    },
    isRoleTeacher() {
      return store.getters["user/roles"].includes("teacher");
    },
    isRoleCompanyAdmin() {
      return (
        store.getters["user/roles"].includes("super-admin") &&
        Theme.name !== "TestAdmit"
      );
    }
  }
};
