import { user } from "@ivy-way/material";
import paymentApi from "@/apis/payment";

const state = {
  incomes: [],
  incomePage: 1,
  totalIncomeNumber: 0,
  expenses: [],
  expensePage: 1,
  totalExpenseNumber: 0,
  transactions: [],
  transactionPage: 1,
  totalTransactionNumber: 0,
  users: [],
  selectedUser: null
};

const formatDisplayName = userInfo => (
  userInfo.name.trim()
    ? user.displayName(userInfo.first_name, userInfo.last_name)
    : "(Registration not completed)"
);

const getters = {
  incomes(state) {
    return state.incomes.map(income => ({
      ...income,
      user: {
        ...income.user,
        name: formatDisplayName(income.user)
      }
    }));
  },
  incomePage(state) {
    return state.incomePage;
  },
  totalIncomeNumber(state) {
    return state.totalIncomeNumber;
  },
  incomePageSize() {
    return 20;
  },
  expenses(state) {
    return state.expenses.map(expense => ({
      ...expense,
      user: {
        ...expense.user,
        name: formatDisplayName(expense.user)
      }
    }));
  },
  expensePage(state) {
    return state.expensePage;
  },
  totalExpenseNumber(state) {
    return state.totalExpenseNumber;
  },
  expensePageSize() {
    return 20;
  },
  transactions(state) {
    return state.transactions.map(transaction => ({
      ...transaction,
      user: {
        ...transaction.user,
        name: formatDisplayName(transaction.user)
      }
    }));
  },
  transactionPage(state) {
    return state.transactionPage;
  },
  totalTransactionNumber(state) {
    return state.totalTransactionNumber;
  },
  transactionPageSize() {
    return 20;
  },
  users(state) {
    return state.users;
  },
  selectedUser(state) {
    return state.selectedUser;
  }
};

const mutations = {
  setIncomes(state, incomes) {
    state.incomes = incomes;
  },
  setIncomePage(state, incomePage) {
    state.incomePage = incomePage;
  },
  setTotalIncomeNumber(state, totalIncomeNumber) {
    state.totalIncomeNumber = totalIncomeNumber;
  },
  setExpenses(state, expenses) {
    state.expenses = expenses;
  },
  setExpensePage(state, expensePage) {
    state.expensePage = expensePage;
  },
  setTotalExpenseNumber(state, totalExpenseNumber) {
    state.totalExpenseNumber = totalExpenseNumber;
  },
  setTransactions(state, transactions) {
    state.transactions = transactions;
  },
  setTransactionPage(state, transactionPage) {
    state.transactionPage = transactionPage;
  },
  setTotalTransactionNumber(state, totalTransactionNumber) {
    state.totalTransactionNumber = totalTransactionNumber;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setSelectedUser(state, selectedUser) {
    state.selectedUser = selectedUser;
  }
};

const actions = {
  async fetchIncomes({ commit }, payload) {
    console.log(payload);
    const {
      data: incomes,
      current_page: incomePage,
      total: totalIncomeNumber
    } = await paymentApi.getIncomes(payload);
    commit("setIncomes", incomes);
    commit("setIncomePage", incomePage);
    commit("setTotalIncomeNumber", totalIncomeNumber);
  },
  async fetchExpenses({ commit }, payload) {
    const {
      data: expenses,
      current_page: expensePage,
      total: totalExpenseNumber
    } = await paymentApi.getExpenses(payload);
    commit("setExpenses", expenses);
    commit("setExpensePage", expensePage);
    commit("setTotalExpenseNumber", totalExpenseNumber);
  },
  async fetchTransactions({ commit }, payload) {
    const {
      data: transactions,
      current_page: transactionPage,
      total: totalTransactionNumber
    } = await paymentApi.getTransactions(payload);
    commit("setTransactions", transactions);
    commit("setTransactionPage", transactionPage);
    commit("setTotalTransactionNumber", totalTransactionNumber);
  },
  async fetchUsers({ commit }, payload) {
    const { users } = await paymentApi.getUsersOfTransactions(payload);
    commit("setUsers", users);
    return users;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
