import Vue from "vue";

import App from "@/App.vue";

import router from "@/router";
import store from "@/store";

import i18n from "@/i18n";
import htmlToPdf from "@/mixins/htmlToPdf.js";
Vue.use(htmlToPdf);

import ApiService from "@/common/api.service";
ApiService.init();
import Theme from "@/common/theme";

import ElementUI from "@/plugin/elementui.js";
import locale from "element-ui/lib/locale/lang/en";

Vue.use(ElementUI, { locale });
/* 
  一些 hidden-尺寸 的 class
  https://element.eleme.io/#/en-US/component/layout#utility-classes-for-hiding-elements
*/

if (Theme.name === "TestAdmit") {
  import("@/assets/scss/ivy-element/index.scss");
} else if (Theme.name === "Ivy-Way") {
  import("@/assets/scss/ivy-element/index.scss");
} else if (Theme.name === "Taoyuan American School") {
  import("@/assets/scss/taoyuan-element/index.scss");
} else {
  import("@/assets/scss/ivy-element/index.scss");
}

// 主要是 helper class，沒有全加
import "@/assets/scss/ivy-bootstrap.scss";

import VueMeta from "vue-meta";
Vue.use(VueMeta);

// 加入 CKEditor
import CKEditor from "ckeditor4-vue";
Vue.use(CKEditor);

Vue.mixin({
  methods: {
    routerPush(route) {
      this.$router.push(route);
    },
    historyBack() {
      this.$router.go(-1);
    }
  }
});

var themeColor = "";
var CompanyName = "";
var CompanyExamName = "";
var CompanyEmail = "";
var isCompany = false;
//主題色的參數
isCompany = Theme.isCompany;
themeColor = Theme.themeColor;
CompanyName = Theme.tabName;
CompanyEmail = Theme.email;
CompanyExamName = Theme.examName;
Vue.prototype.CompanyName = CompanyName;
Vue.prototype.CompanyExamName = CompanyExamName;
Vue.prototype.CompanyEmail = CompanyEmail;
Vue.prototype.isCompany = isCompany;

Vue.config.productionTip = false;
Vue.prototype.themeColor = themeColor;
Vue.prototype.recorderGet = null;

// if(JwtService.hasToken()){
//   if(store.state.user.user === null){
//       store.dispatch('user/getUser').then({})
//   }
// }

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
