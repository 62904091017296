import baseDomainPath from "@/common/baseDomainPath";
let ThemeDetail = null;

console.log(baseDomainPath);
if (baseDomainPath.indexOf("ivy-way") > -1) {
  ThemeDetail = {
    themeColor: "#42a16a",
    logo: "https://ivy-way.com/img/logo.png",
    image: "https://testadmit.s3.ap-northeast-1.amazonaws.com/toefl/img/SATLogo.png",
    icon: "IvyWayfavicon.ico",
    name: "Ivy-Way",
    tabName: "Ivy-Way",
    examName: "Ivy-Way",
    email: "contact@ivy-way.com",
    linkArr: [
      {
        link: "https://ivy-way.com/about",
        name: "about"
      },
      {
        link: "https://ivy-way.com/instructors",
        name: "instructors"
      },
      {
        link: "https://ivy-way.com/classes",
        name: "classes"
      },
      {
        link: "https://ivy-way.com/stories",
        name: "stories",
        icon: "fas fa-user-graduate"
      },
      {
        link: "https://ivy-way.com/blogs",
        name: "blogs"
      }
    ]
  };
}else if (baseDomainPath.indexOf("tyas") > -1) {
  ThemeDetail = {
    themeColor: "#035ecc",
    logo: "https://www.tyas.tyc.edu.tw/__edited_images/CIS%20design/_imagecache/Logo-all-ai.png",
    image: "https://www.tyas.tyc.edu.tw/__edited_images/CIS%20design/_imagecache/Logo-all-ai.png",
    icon: "Taoyuan.ico",
    name: "Taoyuan American School",
    tabName: "Taoyuan American School",
    examName: "Taoyuan American School",
    email: null,
    isDarkBackground:true,
    backgroundColor: "#03073b",
    linkArr: [ {
      link: "https://www.tyas.tyc.edu.tw/",
      name: "home"
    }]
  };
} else {
  ThemeDetail = {
    themeColor: "#42a16a",
    logo: null,
    image: null,
    icon: "SATfavicon.ico",
    name: "TestAdmit",
    tabName: "TestAdmit",
    examName: "TestAdmit",
    email: "support@testadmit.com",
    linkArr: []
  };
}

// ThemeDetail = {
//   themeColor: "#42a16a",
//   logo: "https://ivy-way.com/img/logo.png",
//   image: "https://testadmit.s3.ap-northeast-1.amazonaws.com/toefl/img/SATLogo.png",
//   icon: "IvyWayfavicon.ico",
//   name: "Ivy-Way",
//   tabName: "Ivy-Way",
//   examName: "Ivy-Way",
//   email: "contact@ivy-way.com",
//   linkArr: [
//     {
//       link: "https://ivy-way.com/about",
//       name: "about"
//     },
//     {
//       link: "https://ivy-way.com/instructors",
//       name: "instructors"
//     },
//     {
//       link: "https://ivy-way.com/classes",
//       name: "classes"
//     },
//     {
//       link: "https://ivy-way.com/stories",
//       name: "stories",
//       icon: "fas fa-user-graduate"
//     },
//     {
//       link: "https://ivy-way.com/blogs",
//       name: "blogs"
//     }
//   ]
// };
// ThemeDetail = {
//   themeColor: "#035ecc",
//   logo: "https://www.tyas.tyc.edu.tw/__edited_images/CIS%20design/_imagecache/Logo-all-ai.png",
//   image: "https://www.tyas.tyc.edu.tw/__edited_images/CIS%20design/_imagecache/Logo-all-ai.png",
//   icon: "Taoyuan.ico",
//   name: "Taoyuan American School",
//   tabName: "Taoyuan American School",
//   examName: "Taoyuan American School",
//   email: null,
//   isDarkBackground:true,
//   backgroundColor: "#03073b",
//   linkArr: [ {
//     link: "https://www.tyas.tyc.edu.tw/",
//     name: "home"
//   }]
// };

export default ThemeDetail;
