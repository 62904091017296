import Bowser from "bowser";

export default {
  methods: {
    userAgent() {
      return navigator.userAgent;
    },
    browser() {
      const browser = Bowser.getParser(this.userAgent());
      return browser.getBrowserName();
    },
    isEdge() {
      return this.browser() === "Microsoft Edge";
    },
    isChromium() {
      return ["Microsoft Edge", "Chrome"].includes(this.browser());
    },
    isPC() {
      let userAgentInfo = navigator.userAgent;
      let Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    //获取答案
    //获取答案
    getOption(answer, isCorrect, canswer) {
      let option = "";
      let isOptionCorrect = false;

      if (typeof canswer === "string") {
        isOptionCorrect = answer.indexOf(canswer) > -1;
      }
      if (Array.isArray(canswer)) {
        const jsonCorrectAnswer = canswer.map(item => JSON.stringify(item));
        isOptionCorrect =
          jsonCorrectAnswer.indexOf(JSON.stringify(answer)) > -1;
      }

      if (isCorrect && isOptionCorrect) {
        option = `<span style="color:green"> ${answer} </span>`;
      } else {
        option = `<span style="color:red"> ${answer} </span>`;
      }
      return option;
    },
    getCorrectAnswer(answer) {
      let correctAnswer = "";
      if (answer.length == 1) {
        answer[0].forEach((item, index) => {
          if (index === answer[0].length - 1) {
            correctAnswer += `<span style="color:green"> ${item} </span>`;
          } else {
            correctAnswer += `<span style="color:green"> ${item} <span style='color:black'>|</span></span>`;
          }
        });
      } else if (answer.length == 2) {
        answer[0].forEach(item => {
          correctAnswer += `<span style="color:green"> ${item} </span>`;
        });
        correctAnswer = correctAnswer + ",";
        answer[1].forEach(item => {
          correctAnswer += `<span style="color:green"> ${item} </span>`;
        });
      }
      return correctAnswer;
    },
    getMyAnswer(answer, canswer, is_correct) {
      let myAnswer = "";
      let isCorrect = false;
      if (is_correct == 1) isCorrect = true;
      answer.forEach((item, index) => {
        if (index === answer.length - 1) {
          myAnswer += this.getOption(item, isCorrect, canswer[0]);
        } else {
          myAnswer +=
            this.getOption(item, isCorrect, canswer[0]) +
            " <span style='color:black'>|</span>";
        }
      });
      return myAnswer;
    },
    replaceLine(str) {
      let newStr = "";
      if (str) {
        newStr = str.replace(/\\/g, "");
        newStr = newStr.replace(/\n/g, "<br>");
      }
      return newStr;
    },
    isIOS() {
      let isIOS = false;
      if (navigator.userAgent.indexOf("iPhone") != -1) {
        isIOS = true;
      }
      if (navigator.userAgent.indexOf("iPad") != -1) {
        isIOS = true;
      }
      return isIOS;
    }
  }
};
