<template>
  <el-dialog
    :title="$t('message.notice')"
    :visible.sync="adBlockAlertVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    width="400px"
  >
    <p v-if="isEdge()">
      {{ $t("message.detectedAdBlockEdge1") }}<a href="https://youtu.be/OeudThSYgY4" target="_blank">{{
        $t("message.detectedAdBlockEdge2") }}</a>{{
        $t("message.detectedAdBlockEdge3") }}<a href="https://www.businessinsider.com/microsoft-edge-privacy-ad-blocker-google-chrome-comparison-2020-1" target="_blank">{{
        $t("message.detectedAdBlockEdge4") }}</a>{{
        $t("message.detectedAdBlockEdge5")
      }}
    </p>
    <p v-else-if="isChromium()">
      {{ $t("message.detectedAdBlockChrome1") }}<a href="https://youtu.be/OeudThSYgY4" target="_blank">{{
        $t("message.detectedAdBlockChrome2") }}</a>{{
        $t("message.detectedAdBlockChrome3") }}<a href="https://youtu.be/4xRr5QxnsbA" target="_blank">{{
        $t("message.detectedAdBlockChrome4") }}</a>{{
        $t("message.detectedAdBlockChrome5")
      }}
    </p>
    <p v-else>
      {{ $t("message.detectedAdBlockOther1") }}<a href="https://youtu.be/OeudThSYgY4" target="_blank">{{
        $t("message.detectedAdBlockOther2") }}</a>{{
        $t("message.detectedAdBlockOther3")
      }}
    </p>
    <p class="becomePro">
      {{ $t("message.becomeProToRemoveAdBlock1") }}<router-link :to="{ name: 'ToeflPlans' }">{{
        $t("message.becomeProToRemoveAdBlock2") }}</router-link>{{
          $t("message.becomeProToRemoveAdBlock3")
        }}
        😇
    </p>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="checkAdBlock">
        {{ $t("alert.refresh") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import detectAdBlock from "@/common/detectAdBlock";
import ToeflMixin from "@/mixins/toefl.js";

export default {
  data() {
    return {
      countryCode: null,
      adBlockAlertVisible: false
    };
  },
  mixins: [ToeflMixin],
  computed: {
    isLogin() {
      return this.$store.getters["user/token"] !== "";
    },
    profile() {
      return this.$store.getters["user/getProfile"];
    }
  },
  async mounted() {
    const { iso_code } = await this.$store.dispatch("ipGeo/getGeoLocation");
    this.countryCode = iso_code;
    this.detectAdBlock();
  },
  methods: {
    async detectAdBlock() {
      if (
        this.profile.all_roles.includes("super-admin", "pro") ||
        this.countryCode === "CN"
      ) {
        return;
      }
      if (this.isLogin) {
        const isExistAdBlock = await detectAdBlock();
        this.$emit("afterFound", isExistAdBlock);
        this.adBlockAlertVisible = isExistAdBlock;
      }
    },
    async checkAdBlock() {
      const isExistAdBlock = await detectAdBlock();
      if (isExistAdBlock) {
        this.$message.warning(this.$t("message.closeAdBlock"));
      } else {
        window.location.reload();
      }
    }
  }
};
</script>
<style scoped>
::v-deep .el-dialog__body {
  padding: 0px 20px;
}

::v-deep .el-dialog__body > p {
  margin-bottom: 0px;
}

.becomePro {
  margin-top: 14px;
}
</style>
