import _ from "lodash";
import flashcardsApi from "@/apis/flashcards";

const state = {
  vocabBooks: [],
  vocabLists: [],
  vocabList: {},
  vocabCollections: [],
  vocabListsCount: 0,
  currentVocabListsPage: 1
};

const getters = {
  vocabBooks(state) {
    return state.vocabBooks;
  },
  vocabLists(state) {
    const removeTitle = title => title.split(" ").slice(1).join(" ");
    return state.vocabLists.map(vocabList => ({
      ...vocabList,
      list: vocabList.vocab_lists.map(list => ({
        ...list,
        title: removeTitle(list.title)
      }))
    }));
  },
  vocabListsCount(state) {
    return state.vocabListsCount;
  },
  currentVocabListsPage(state) {
    return state.currentVocabListsPage;
  },
  vocabList(state) {
    return state.vocabList;
  },
  vocabCollections(state) {
    return state.vocabCollections;
  }
};

const mutations = {
  initVocabList(state) {
    state.vocabList = {};
  },
  setVocabBooks(state, vocabBooks) {
    state.vocabBooks = vocabBooks;
  },
  setCurrentVocabListsPage(state, currentVocabListsPage) {
    state.currentVocabListsPage = currentVocabListsPage;
  },
  setVocabListsCount(state, vocabListsCount) {
    state.vocabListsCount = vocabListsCount;
  },
  setVocabLists(state, vocabLists) {
    state.vocabLists = vocabLists;
  },
  setVocabList(state, vocabList) {
    state.vocabList = vocabList;
  },
  setVocabCollections(state, vocabCollections) {
    state.vocabCollections = vocabCollections;
  }
};

const actions = {
  async fetchVocabBooks({ commit }) {
    const { vocab_books } = await flashcardsApi.fetchVocabBooks();
    commit("setVocabBooks", vocab_books);
  },
  async fetchVocabBook({ commit }, { vocabBookId, page }) {
    const { data, total } = await flashcardsApi.fetchVocabBook(vocabBookId, page);
    commit("setVocabLists", data);
    commit("setVocabListsCount", total);
    commit("setCurrentVocabListsPage", page);
  },
  async fetchVocabList({ commit }, listId) {
    const {
      vocab_backs,
      vocab_list_titles
    } = await flashcardsApi.fetchVocabList(listId);
    commit("setVocabList", {
      vocabularies: vocab_backs,
      list: {
        id: listId,
        title: vocab_list_titles[0],
        count: vocab_backs.length
      }
    });
  },
  async addVocabMemory({ dispatch }, { vocabularyId, memory }) {
    await flashcardsApi.addVocabMemory(
      vocabularyId,
      memory.memory,
      memory.is_public
    );
    dispatch("updateVocabularyStatus", vocabularyId);
  },
  async updateVocabMemory({ dispatch }, { vocabularyId, vocabMemoryId, memory }) {
    await flashcardsApi.updateVocabMemory(
      vocabMemoryId,
      memory.memory,
      memory.is_public
    );
    dispatch("updateVocabularyStatus", vocabularyId);
  },
  async deleteVocabMemory({ dispatch }, { vocabularyId, vocabMemoryId }) {
    await flashcardsApi.deleteVocabMemory(vocabMemoryId);
    dispatch("updateVocabularyStatus", vocabularyId);
  },
  async likeVocabMemory({ dispatch }, { vocabularyId, vocabMemoryId }) {
    await flashcardsApi.likeVocabMemory(vocabMemoryId);
    dispatch("updateVocabularyStatus", vocabularyId);
  },
  async fetchCollectionVocabularies({ commit }, listId) {
    const {
      vocab_backs,
      vocab_collection_title
    } = await flashcardsApi.fetchCollectionVocabularies(listId);
    commit("setVocabList", {
      vocabularies: vocab_backs,
      list: {
        id: listId,
        title: vocab_collection_title,
        count: vocab_backs.length
      }
    });
  },
  async fetchVocabCollections({ commit }, { page }) {
    const { vocab_collections: { data, total } } = await flashcardsApi.fetchVocabCollections(page);
    commit("setVocabCollections", data);
    commit("setVocabListsCount", total);
    commit("setCurrentVocabListsPage", page);
  },
  async createVocabCollection({_}, collection) {
    await flashcardsApi.createVocabCollection(
      collection.title,
      collection.description
    );
  },
  async updateVocabCollection({_}, collection) {
    await flashcardsApi.updateVocabCollection(
      collection.collectionId,
      collection.title,
      collection.description
    );
  },
  async deleteVocabCollection({_}, collectionId) {
    await flashcardsApi.deleteVocabCollection(collectionId);
  },
  async addVocabToCollection({_}, { vocabularyId, collectionId }) {
    await flashcardsApi.addVocabToCollection(vocabularyId, collectionId);
  },
  async removeVocabFromCollection({_}, { vocabularyId, collectionId }) {
    await flashcardsApi.removeVocabFromCollection(vocabularyId, collectionId);
  },
  async updateVocabularyStatus({ state, commit }, vocabId) {
    const { vocab_back: newVocab } = await flashcardsApi.fetchVocabulary(vocabId);
    const newVocabularies = state.vocabList.vocabularies.map(vocab => {
      if (vocab.id === vocabId) {
        return newVocab;
      } else {
        return vocab;
      }
    });
    commit("setVocabList", {
      ...state.vocabList,
      vocabularies: newVocabularies
    });
  },
  removeTopVocabulary({ commit, state }) {
    let removedVocabularies = [...state.vocabList.vocabularies];
    removedVocabularies.shift();

    commit("setVocabList", {
      ...state.vocabList,
      vocabularies: removedVocabularies
    });
  },
  putTopVocabPackToCards({ commit, state }, puttingWay) {
    let vocabularies = [...state.vocabList.vocabularies];
    const theTopVocab = vocabularies.shift();

    // 直接用可被 3 整除的長度
    const vocabularyCount = vocabularies.length - (vocabularies.length % 3);
    let [start, end] = [0, vocabularyCount];

    switch (puttingWay) {
      case "hard":
        end = vocabularyCount / 3;
        break;
      case "good":
        start = vocabularyCount / 3;
        end = (vocabularyCount / 3) * 2;
        break;
      case "easy":
        start = (vocabularyCount / 3) * 2;
        break;
      default:
        throw `error putting way ${puttingWay}`;
    }

    // start + 1 怕又出現在第一個，end - 1 怕超出 array 範圍
    const randomPosition = _.random(start + 1, end - 1);
    vocabularies.splice(randomPosition, 0, theTopVocab);
    commit("setVocabList", { ...state.vocabList, vocabularies });
  },
  async createMnemonic({ commit, state }, { vocabularyId, mnemonic }) {
    const newVocabularies = state.vocabList.vocabularies.map(vocab => ({
      ...vocab,
      mnemonics: [...(vocab.mnemonics || []), mnemonic]
    }));

    commit("setVocabList", {
      ...state.vocabList,
      vocabularies: newVocabularies
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
