import ApiService from "@/common/api.service";

export default {
  getPromotionCodeRecords() {
    return ApiService.query("promotion-code-records");
  },
  register(data) {
    return ApiService.post("register", data);
  },
  login(data) {
    return ApiService.post("login", data);
  },
  ivyWayLogin(data) {
    return ApiService.post("ivy-way/login", data);
  },
  getProfile() {
    return ApiService.post("me");
  },
  logout() {
    return ApiService.post("logout");
  },
  checkUsernameIsDuplicate(data) {
    return ApiService.post("check-username", data);
  },
  searchStudents(data) {
    return ApiService.query("students", data);
  },
  searchParents(data) {
    return ApiService.query("parent/search", data);
  },
  getCaptcha() {
    return ApiService.query("/captcha/flat");
  },
  getStudentTestAnalysis(data) {
    return ApiService.query("student/test-analysis", data);
  },
  getStudentByParentId(parentId) {
    return ApiService.query(`parent/${parentId}/children`);
  },
  saveLang(data) {
    return ApiService.patch("lang", data);
  },
  verifyEmail(data) {
    return ApiService.query("/verify", data);
  },
  getReferralCode() {
    return ApiService.query("/referral-code");
  },
  checkReferralCode(data) {
    return ApiService.post("/referral-code/check", data);
  },
  getReferralUsed() {
    return ApiService.query("/referral-code/used ");
  },
  sendVerifyEmail() {
    return ApiService.post("/send-verify-email");
  },
  exchangePoint() {
    return ApiService.post("/referral-code/exchange-point");
  },
  deleteUser(user_id) {
    return ApiService.delete("account/destroy/" + user_id);
  },
  getUsers(data) {
    return ApiService.query("admin/user", data);
  },
  updateUserProExpiredDate(userId, expiredDate) {
    return ApiService.patch(`admin/user/${userId}/pro`, {
      taken_unlimited_expire_at: expiredDate
    });
  },
  giveTCoin(userId, tCoin) {
    return ApiService.post(`admin/user/${userId}/give-point`, {
      point: tCoin
    });
  },
  deductTCoin(userId, tCoin) {
    return ApiService.post(`admin/user/${userId}/deduct-point`, {
      point: tCoin
    });
  },
  removePro(userId) {
    return ApiService.delete(`admin/user/${userId}/pro`);
  },
  switchBlockStatus(userId) {
    return ApiService.post(`admin/user/${userId}/toggle-block`);
  },
  switchProStatusPermission(userId) {
    return ApiService.post(`admin/user/${userId}/toggle-old-pro`);
  },
  patchSetting(data) {
    return ApiService.patch("toefl-settings", data);
  },
  getSetting() {
    return ApiService.get("toefl-settings");
  }
};
